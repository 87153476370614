<div class="relative w-full text-black dark:text-white flex flex-col min-h-[240px] rounded-lg rounded-t-none
pt-6 h-full bg-white dark:bg-black border-primary dark:border-secondary border transition-all hover:shadow-lg"
    *ngIf="service$ | async as item; else loading">
    <div class="px-3 flex-grow flex flex-col">
        <div class="flex items-start justify-between mb-5">
            <h2 class="w-full min-h-16 text-2xl text-center font-semibold flex justify-center">
                <div [ngClass]="{'line-through': item.service?.alerts?.length}">{{item.service?.name}}</div>
                <div *ngIf="item.service?.alerts?.length" [title]="item.service?.alerts?.[0]?.title">*</div>
            </h2>
            <!-- <div *ngIf="!!item.price" class="flex l-1 px-1 justify-center items-center py-1 text-xs  ">
                <div class="rounded-full text-yellow-200  bg-yellow-600 flex p-2">
                    <svg class="h-8 w-8 text-yellow-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
            </div> -->
        </div>
        <div class="pb-2 w-full flex justify-around items-end flex-grow">
            <ng-container *ngIf="serviceIcon || serviceImg; else noIcon">
                <div *ngIf="serviceIcon">
                    <i class="text-secondary fa h-16" [ngClass]="[serviceIcon]"> </i>
                </div>
                <div *ngIf="serviceImg">
                    <img [src]="serviceImg" class="h-16">
                </div>
            </ng-container>
            <ng-template #noIcon>
                <div *ngIf="item.service?.executionPolicy && executionPolicyMap[item.service?.executionPolicy || ''] as policy; else customPolicy"
                    class="flex flex-col items-center">
                    <i class="text-primary fa w-8 h-8" [ngClass]="policy.icon"
                        [title]="'Evasione in ' + policy.text"></i>
                    <div class="capitalize text-center text-small">{{policy.text}}</div>
                </div>
                <ng-template #customPolicy>
                    <div>
                        <i class="text-secondary fa h-16" [ngClass]="[serviceIcon]"> </i>
                    </div>
                </ng-template>
                <div *ngIf="item.service?.targets?.length" class="ml-4">
                    <span class="ml-2">
                        <span *ngFor="let target of item.service?.targets; let i = index">
                            <i class="text-gray-400 fa fa-solid m-1"
                                [ngClass]="[targetMap[target].icon,(item.service?.targets?.length || 1) > 2? 'h-9': 'h-12' ]"
                                [title]="'Richiedibile su ' + targetMap[target].text"></i></span>
                    </span>
                </div>
            </ng-template>
        </div>
        <div>
            <button *ngIf="item.service?.description" class="text-primary h-7" (click)="open=true"
                data-cy="read-all">Leggi
                tutto</button>
            <div></div>
        </div>

    </div>
    <div
        class="text-sm w-full font-semibold flex justify-between  gap-2 text-white bg-primary py-3 px-5 rounded-b-lg  border-t border-gray-200 transition-colors">
        <div class="flex items-center text-sm sm:space-x-12 justify-between h-8">
            <div *ngIf="(!!item.price || !!item.service?.priceDescription) && item.isActive"
                class="cursor-pointer relative group rounded-full text-yellow-600  bg-yellow-200 flex px-2 py-1">
                <svg class="mr-1 h-5 w-5 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>vedi&nbsp;costo
                <div
                    class="opacity-0 bg-secondary dark:bg-white text-white w-80 max-h-40 overflow-y-auto scale-0 group-hover:scale-100 origin-bottom-left dark:text-black shadow-md text-justify text-xs rounded-lg py-2 absolute z-10 duration-700 group-hover:opacity-100 bottom-full mb-2 left-0 px-3 pointer-events-none">
                    <div *ngIf="item.service?.priceDescription; else standardPrice"
                        [innerHTML]="item.service?.priceDescription"></div>
                    <ng-template #standardPrice>
                        <div>{{item.price}}€ per ciascuna richiesta</div>
                    </ng-template>
                    <svg class="absolute text-secondary dark:text-white h-2 w-28 left-0 top-full" x="0px" y="0px"
                        viewBox="0 0 255 255" xml:space="preserve">
                        <polygon class="fill-current" points="0,0 127.5,127.5 255,0" />
                    </svg>
                </div>
            </div>
        </div>
        <button disabled
            *ngIf="!item.service?.alerts?.length && item.isActive &&item.service?.status == 'COMINGSOON'; else link"
            data-cy="service-link"
            class="px-2 py-1 border-white hover:text-secondary hover:bg-white  bg-secondary cursor-not-allowed border rounded transition-colors">
            {{ buttonText || 'PRESTO DISPONIBILE' }}
        </button>
        <ng-template #link>
            <ng-container *ngIf="!item.service?.alerts?.length">
                <a *ngIf="!preventRoute" [routerLink]="forceLink  || ['/','user','service', item.service?.slug]" actions
                    data-cy="service-link"
                    class="px-2 py-1 border-white hover:text-primary hover:bg-white  border rounded transition-colors">
                    {{ buttonText || 'RICHIEDI' }}
                </a>
                <button *ngIf="preventRoute" actions (click)="onServiceSelected.emit(item)" data-cy="service-link"
                    class="px-2 py-1 border-white hover:text-primary hover:bg-white  border rounded transition-colors">
                    {{ buttonText || 'RICHIEDI' }}
                </button>
            </ng-container>

        </ng-template>
    </div>

    <div *ngIf="open"
        class="fixed z-40 md:py-4 lg:py-10 inset-0 w-full h-full flex items-center justify-center dark:text-white text-black bg-black dark:bg-white bg-opacity-30">
        <div
            class="rounded-lg shadow-2xl max-w-xl max-h-full px-4 py-6 bg-white  dark:bg-black border-t-2 border-primary flex flex-col">
            <div class="flex items-center justify-between space-x-2 mb-6">

                <h2 class="text-2xl font-bold hover:shadow-light-100" backheader>
                    {{item.service?.name}}
                </h2>
                <button (click)="open=false"><svg class="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-cy="close">
                        <circle cx="12" cy="12" r="10" />
                        <line x1="15" y1="9" x2="9" y2="15" />
                        <line x1="9" y1="9" x2="15" y2="15" />
                    </svg></button>
            </div>
            <div class="max-h-full pb-2 mb-2 justify-between border-b border-white dark:border-black overflow-y-scroll"
                [innerHtml]="item.service?.description">

            </div>

        </div>
    </div>
    <div *ngIf="!item.isActive"
        class="absolute inset-0 backdrop-blur-[1px] backdrop-grayscale z-10 flex justify-center items-end">
        <div class="p-4 font-bold text-xl text-white">Il servizio non è attivo</div>
    </div>
</div>
<ng-template #loading>
    <div class="animate-pulse w-full text-black dark:text-white flex flex-col min-h-[240px] rounded-lg justify-center items-center
    h-full bg-white dark:bg-black border-primary dark:border-secondary border transition-all hover:shadow-lg">
        <div class="bg-primary w-5 h-5 rounded-full"></div>
    </div>
</ng-template>