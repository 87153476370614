import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from '../user/messages/messages.component';
import { ConsultazioneCatastoComponent } from './vv/catasto/consultazione.component';
import { CardComponent } from './vv/catasto/components/card/card.component';
import { DocumentsComponent } from './vv/documents/document.component';
import { ConservatoriaComponent } from './vv/conservatoria/conservatoria.component';
import { MonitoraggioComponent } from './vv/monitoraggio/monitoraggio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { SecuresuiteComponent } from './ss/securesuite/securesuite.component';
import { EventiComponent } from './vv/monitoraggio/eventi/eventi.component';
import { PregiudizievoliComponent } from './vv/pregiudizievoli/pregiudizievoli.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ListaFormalitaComponent } from './vv/conservatoria/lista-formalita/lista-formalita.component'
import { ListaPregiudizievoliComponent } from './vv/pregiudizievoli/lista-pregiudizievoli/lista-pregiudizievoli.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MonitoraggioReoperaComponent } from './vv/monitoraggio_reopera/monitoraggio.component';
import { DossierComponent } from './dossier/dossier/dossier.component';
import { BilanciComponent } from './vv/bilanci/bilanci.component';
import { MappaComponent } from './vv/geocatasto/mappa/mappa.component';
import { GeoCatastoComponent } from './vv/geocatasto/geocatasto.component';
import { LowerPanelComponent } from './vv/geocatasto/lower-panel/lower-panel.component';
import { SidePanelComponent } from './vv/geocatasto/side-panel/side-panel.component';
import { RicercheComponent } from './vv/ricerche/ricerche.component';
import { ReoperaPlusComponent } from './reopera-plus/reopera-plus.component';

@NgModule({
  declarations: [
    DocumentsComponent,
    ConsultazioneCatastoComponent,
    ConservatoriaComponent,
    PregiudizievoliComponent,
    CardComponent,
    MonitoraggioComponent,
    MessagesComponent,
    SecuresuiteComponent,
    EventiComponent,
    HelpdeskComponent,
    GeoCatastoComponent,
    ListaFormalitaComponent,
    ListaPregiudizievoliComponent,
    MonitoraggioReoperaComponent,
    DossierComponent,
    BilanciComponent,
    MappaComponent,
    LowerPanelComponent,
    SidePanelComponent,
    RicercheComponent,
    ReoperaPlusComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    AutocompleteLibModule,
    NgxPaginationModule,
    LeafletModule,
    BrowserAnimationsModule,
  ],
  exports: [
    DocumentsComponent
  ]
})
export class PagesModule { }
