import { EntryPoint, NavigationConfig } from "src/app/models/navigation.shared.model"
import { FieldPatterns, ServiceParamFieldModel } from "src/app/models/service.model"

const conservatoria_navigation_beni: NavigationConfig = {
    name: "Beni",
    nameAfterSelect: "Bene selezionato",
    channel: "catasto:soggetti:uffici:immobili",
    inputParams: ["codice_ufficio"],
    outputParamName: "id_bene",
    isSelectable: true,
    details: [
        { title: "Ubicazione", description: "", icon: "marker", field: "ubicazione" },
        { title: "Classamento", description: "", icon: "tag", field: "_classamento" },
        { title: "Consistenza", description: "", icon: "room", field: "_consistenza" },
        { title: "Rendita", description: "", icon: "money", field: "_rendita", suffix: "€" },
        { title: "Titolarità", description: "", icon: "pie", field: "titolarita" },
        { title: "Tipo catasto", description: "", icon: "building", field: "tipo_catasto" },
        { title: "Identificazione", description: "Foglio/Particella/Subalterno", icon: "building", field: ['foglio', 'particella', 'subalterno'], separator: "/" }],
    actions: [
        { title: "Visura Ipotecaria", slug: "visura-ipotecaria", type: "request" }
    ]
}
export const formalita_navigation: NavigationConfig = {
    name: "Formalità",
    nameAfterSelect: "Formalità selezionata",
    channel: "conservatoria:formalita:lista",
    outputParamName: "id_formalita",
    extraParamNames: [{
        from: 'data_formalita',
        to: 'anno'
    }, {
        from: 'data_formalita',
        to: 'data_formalita'
    }, {
        from: 'numero_particolare',
        to: 'numero_registro_particolare'
    }, {
        from: 'numero_generale',
        to: 'numero_registro_generale'
    }, {
        from: 'tipo_formalita',
        to: 'tipo_nota'
    }, {
        from: 'genere',
        to: 'genere_nota'
    }, {
        from: 'favore_contro',
        to: 'favore_contro'
    }, {
        from: '_immobili_siti_in',
        to: '_immobili_siti_in'
    }],
    isSelectable: false,
    details: [
        { title: "Genere", description: "", icon: "user", field: 'genere' },
        { title: "Favore / Contro", description: "", icon: "user", field: 'favore_contro' },
        { title: "Data Formalità", description: "", icon: "user", field: 'data_formalita' },
        { title: "Reg. Part.", description: "", icon: "user", field: 'numero_particolare' },
        { title: "Reg. Gen.", description: "", icon: "user", field: 'numero_generale' },
        { title: "Immobili siti in", description: "", icon: "user", field: '_immobili_siti_in' },
        { title: "Specie", description: "", icon: "user", field: 'specie_formalita' },
        { title: "Tipo", description: "", icon: "user", field: 'tipo_formalita' },
        // { title: "Pub. Uff.", description: "", icon: "user", field: 'pubblico_ufficiale' },
        // { title: "Rep.", description: "", icon: "user", field: 'repertorio' },
        // { title: "Data Repertorio", description: "", icon: "user", field: 'data_repertorio' },
        // { title: "Data", description: "Formalità - Repertorio", icon: "user", field: '_date' },
    ],
    actions: [
        { title: "Dettaglio nota", slug: "conservatoria-nota", type: "request" },
        { title: "Copia titolo telematico", slug: "conservatoria-titolo", type: "request" }
    ]
}
const conservatoria_soggetti_navigations: NavigationConfig[] = [
    formalita_navigation
]

const tipo_annotazioni: ServiceParamFieldModel[] = [
    {
        name: "data_da",
        description: "Data da",
        type: "hidden"
    },
    {
        name: "data_a",
        description: "Data a",
        type: "hidden"
    },
    {
        name: "includi_annotazioni_contro",
        description: "Includi annotazioni contro",
        type: "switch",
        half: 'left',
        defaultValue: "true"
    }, {
        name: "includi_annotazioni_favore",
        description: "Includi annotazioni a favore",
        type: "switch",
        half: 'right',
        defaultValue: "true"
    }, {
        name: "includi_iscrizioni_contro",
        description: "Includi iscrizioni contro",
        type: "switch",
        half: 'left',
        defaultValue: "true"
    }, {
        name: "includi_iscrizioni_favore",
        description: "Includi iscrizioni favore",
        type: "switch",
        half: 'right',
        defaultValue: "true"
    }, {
        name: "includi_trascrizioni_contro",
        description: "Includi trascrizioni contro",
        type: "switch",
        half: 'left',
        defaultValue: "true"
    }, {
        name: "includi_trascrizioni_favore",
        description: "Includi trascrizioni favore",
        type: "switch",
        half: 'right',
        defaultValue: "true"
    }, {
        name: "escludi_ipoteche_non_rinnovate_o_cancellate",
        description: "Escludi ipoteche non rinnovate",
        type: "switch",
        half: 'left',
    }, {
        name: "escludi_trascrizioni_non_rinnovate",
        description: "Escludi trascrizioni non rinnovate",
        type: "switch",
        half: 'left'
    }]
export const conservatoriaConfig: EntryPoint[] = [
    {
        name: "Ricerca per persona fisica",
        isActive: true,
        serviceSlug: 'conservatoria-ricerca-soggetto',
        fields: [{
            name: "codice_fiscale",
            description: "Codice Fiscale",
            pattern: "CF"
        }, {
            name: "cognome",
            description: "Cognome",
            required: true
        }, {
            name: "nome",
            description: "Nome",
            required: true
        }, {
            name: "territorio",
            description: "Comune o Conservatoria",
            note: "Inserisci il comune, il sistema selezionerà automaticamente la conservatoria di appartenenza.",
            type: "autocomplete",
            inputChannel: "conservatoria:ricerca:comuni",
            defaultValue: 'IT',
            extra: {
                valueField: 'codice_conservatoria',
            },
            required: true
        },
        ...tipo_annotazioni,
        {
            name: "_tipo_soggetto",
            description: "tipo soggetto",
            type: "hidden",
            defaultValue: "personafisica"

        }],
        navigations: conservatoria_soggetti_navigations,
        paymentRequired: true,
        paymentExtraText: ' ricerca + massimo 30 note',
        confirmPayment: false,
        submitText: 'Verifica costo'
    }, {
        name: "Ricerca per persona giuridica",
        serviceSlug: 'conservatoria-ricerca-soggetto',
        isActive: true,
        fields: [{
            name: "codice_fiscale",
            description: "Codice Fiscale",
            pattern: "COMPANY_CF"
        }, {
            name: "denominazione",
            description: "Denominazione",
            required: true
        }, {
            name: "territorio",
            description: "Comune o Conservatoria",
            note: "Inserisci il comune, il sistema selezionerà automaticamente la conservatoria di appartenenza.",
            type: "autocomplete",
            inputChannel: "conservatoria:ricerca:comuni",
            defaultValue: 'IT',
            extra: {
                valueField: 'codice_conservatoria',
            },
            required: true
        },
        ...tipo_annotazioni,
        {
            name: "_tipo_soggetto",
            description: "tipo soggetto",
            type: "hidden",
            defaultValue: "personagiuridica"

        }],
        navigations: conservatoria_soggetti_navigations,
        paymentRequired: true,
        confirmPayment: false,
        paymentExtraText: ' (ricerca + max 30 note)',
        submitText: 'Verifica costo'
    },
    {
        name: "Ricerca per immobile",
        isActive: true,
        serviceSlug: 'conservatoria-ricerca-immobile',
        fields: [
            {
                name: "tipo_catasto",
                description: "Tipo catasto",
                type: "radio",
                items: [{
                    text: "Fabbricati",
                    value: "FABBRICATI"
                }, {
                    text: "Terreni",
                    value: "TERRENI"
                }],
                defaultValue: 'FABBRICATI',
                required: true
            }, {
                name: "territorio",
                description: "Comune o Conservatoria",
                note: "Inserisci il comune, il sistema selezionerà automaticamente la conservatoria di appartenenza.",
                type: "autocomplete",
                inputChannel: "conservatoria:ricerca:comuni",
                extra: {
                    valueField: 'codice_conservatoria',
                    cascadeFields: [{ incomingFieldName: 'codice_comune_conservatoria', outputFieldName: 'codice_comune_conservatoria' }]
                },
                required: true
            }, {
                name: "codice_comune_conservatoria",
                description: "",
                note: "",
                type: "hidden"
            }, {
                name: "foglio",
                description: "Foglio",
                required: true
            }, {
                name: "particella",
                description: "Particella",
                required: true
            }, {
                name: "particella_secondaria",
                description: "Particella secondaria"
            }, {
                name: "subalterno",
                description: "Subalterno"
            },
            ...tipo_annotazioni
        ],
        navigations: conservatoria_soggetti_navigations,
        paymentRequired: true,
        paymentExtraText: ' ricerca + massimo 30 note',
        confirmPayment: false,
        submitText: 'Verifica costo'
    },
    // {
    //     name: "Ricerca da dati nota",
    //     isActive: true,
    //     serviceSlug: 'conservatoria-nota',
    //     fieldsFromService: true,
    //     fields: [],
    //     paymentRequired: true,
    //     navigations: conservatoria_soggetti_navigations,
    //     paymentExtraText: ' ricerca + massimo 30 note',
    //     confirmPayment: false,
    //     submitText: 'Verifica costo e avvia ricerca'
    // },
]
