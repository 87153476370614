import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, Observable, of, tap, delay, catchError } from 'rxjs';
import { EntryPoint } from 'src/app/models/navigation.shared.model';
import { RequestModel } from 'src/app/models/request.model';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { ActionComponentData } from 'src/app/shared/components/actionpopup/actionpopup.component';
import { GetRichiestePerSlugGQL } from 'src/graphql/generated';
import { QueriesService } from '../services/queries.service';
import { SubscriptionService } from '../services/subscription.service';
import { conservatoriaConfig, formalita_navigation } from './conservatoria.ui.config';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-conservatoria',
  templateUrl: './conservatoria.component.html',
  styles: [
  ]
})
export class ConservatoriaComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private queryService: QueriesService,
    private subscriptionService: SubscriptionService,
    private getVisureIpotecarieService: GetRichiestePerSlugGQL,
    private sharedService: SharedService
  ) { }
  listLayout: boolean = true
  navigation = formalita_navigation
  visuraSubscription?: SubscriptionModel
  entrypoints: EntryPoint[] = conservatoriaConfig
  shortcuts$?: Observable<{ title: string, subtitle: string, uuid: string, date: string | Date }[]>

  // subject list
  firstStageData: ActionComponentData = {
    title: "Seleziona l'elemento di interesse"
  }
  firstStageItems: any = []
  firstStageDataSelectionPopupOpen: boolean = false
  firstStageSelectedItem = -1
  firstStageTrackingId?: string
  secondStageData: ActionComponentData = {}
  secondStageCallId?: string
  serviceSlug = 'visura-ipotecaria'
  preventivoChannel = 'conservatoria:preventivo'

  ngOnInit(): void {

    this.subscriptionService.getByServiceSlug(this.serviceSlug).subscribe((a) =>
      this.visuraSubscription = a
    )

    this.shortcuts$ = this.getVisureIpotecarieService.watch({ _eq: this.serviceSlug }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
      map(s => s.data.request),
      map(s => s.map(
        r => ({
          title: r.params._description || (r.params.id_bene && (`${r.params.tipo_catasto || ''} ` + [r.params.foglio, r.params.particella, r.params.subalterno].filter(a => a).join('/'))) || 'Richiesta generica',
          subtitle: r.params.codice_fiscale,
          uuid: r.params.preventivo?.value || r.params.callId,
          date: r.createdAt || ''
        })
      ))
    )

  }

  onSubjectSelection() {
    this.firstStageDataSelectionPopupOpen = false;
    this.sharedService.loadingMsg$ = 'Caricamento preventivo... '
    console.log("PREVENTIVO DATA", this.firstStageItems)
    // ask preventivo

    this.queryService.post({
      channel: this.preventivoChannel,
      data: {
        id_soggetto_o_immobile: this.firstStageItems.items[this.firstStageSelectedItem]?.value
      },
      trackingId: this.firstStageTrackingId
    }).pipe(
      map((res) => {
        console.log("PREVENTIVO", res)
        if (res.error) {
          this.sharedService.loadingMsg$ = 'Oops, abbiamo avuto un problema... attendi un attimo e riprova!'
          setTimeout(() => {
            this.sharedService.loadingMsg$ = ""
            this.firstStageDataSelectionPopupOpen = true
          }, 5000)
        } else {
          this.sharedService.loadingMsg$ = ''
          this.secondStageData = {
            channel: this.serviceSlug,
            subscriptionId: this.visuraSubscription?.id || 0,
            description: "Numero formalità trovate: " + (res?.items?.[0]?.raw?._numero_formalita || ''),
            price: res?.items?.[0]?.raw?._costo || 0,
            title: `${this.visuraSubscription?.service?.name} - ${this.firstStageItems.items[this.firstStageSelectedItem].text}`,
            showZeroPrice: true
          }
          this.secondStageCallId = res?.items?.[0]?.raw?.callId
          this.sharedService.showActionPopup({
            data: this.secondStageData,
            priceLabel: "Costo ulteriore",
            buttonLabel: "Procedi"
          }).subscribe(() => this.onServiceConfirm())
        }
      })).subscribe()

  }

  onServiceConfirm() {
    if (this.secondStageData.subscriptionId) {
      // make request to proper service and await response
      this.sharedService.hideActionPopup()

      let usefullData = this.firstStageItems?.items?.[this.firstStageSelectedItem]?.raw || {}
      let secondStageParams = { ...(usefullData), callId: this.secondStageCallId }
      this.sharedService.submitRequestWithParamsAndNotes({
        params: secondStageParams,
        notes: {},
        confirmActionData: this.secondStageData,
        msg: 'Caricamento formalità... ',
        trackingId: this.firstStageTrackingId
      }).pipe(
        map((res: RequestModel) => {
          this.sharedService.loadingMsg$ = ""
          // go to details
          this.router.navigate([res.params.callId], { relativeTo: this.route })
        })
      ).subscribe()
    }
  }

  searchSubjects(res: RequestModel) {
    this.firstStageTrackingId = res.trackingId
    this.firstStageItems = res.queryResult
    this.firstStageDataSelectionPopupOpen = true
  }
}
