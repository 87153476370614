export const environment = {
  production: false,
  theme: 'confidence',
  graphql_endpoint: 'https://hasura.app.checkimpresa.it/v1/graphql',
  ws_graphql: 'wss://hasura.app.checkimpresa.it/v1/graphql',
  admin_endpoint: 'https://admin.confidence.checkimpresa.it/',
  logo: 'logo-confidence.png',
  favicon: 'favicon-confidence.png',
  title: "Confidence",
  auth: {
    domain: "cbi-it.eu.auth0.com",
    clientId: "z6Y7KhhgPnbkV0tsHbkfH0MqjSEj7oQ2",
    audience: 'CBI-API', // <-- will come back to this
    redirectUrl: window.location.origin,
    httpInterceptor: {
      allowedList: [
        // Attach access tokens to any calls that start with '/api/'
        'https://api.app.checkimpresa.it/api/*',
        'https://confidence.checkimpresa.it/*',
        'https://hasura.app.checkimpresa.it/*',
        '/api/*',
      ],
    }
  }
};
