import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ROLE } from './role.enum';
import { DocumentsComponent } from './pages/vv/documents/document.component';
import { RequestformComponent } from './pages/vv/components/requestform/requestform.component';
import { ConservatoriaComponent } from './pages/vv/conservatoria/conservatoria.component';
import { MessagesComponent } from './user/messages/messages.component';
import { UserDashboardComponent } from './user/user-dashboard.component';
import { ConsultazioneCatastoComponent } from './pages/vv/catasto/consultazione.component';
import { WorkInProgressComponent } from './shared/components/work-in-progress/work-in-progress.component';
import { SecuresuiteComponent } from './pages/ss/securesuite/securesuite.component';
import { PregiudizievoliComponent } from './pages/vv/pregiudizievoli/pregiudizievoli.component';
import { WorkareaComponent } from './shared/components/workarea/workarea.component';
import { HelpdeskComponent } from './pages/helpdesk/helpdesk.component';
import { BalanceComponent } from './user/balance/balance.component';
import { ListaFormalitaComponent } from './pages/vv/conservatoria/lista-formalita/lista-formalita.component';
import { ListaPregiudizievoliComponent } from './pages/vv/pregiudizievoli/lista-pregiudizievoli/lista-pregiudizievoli.component';
import { MonitoraggioReoperaComponent } from './pages/vv/monitoraggio_reopera/monitoraggio.component';
import { RequestsComponent } from './user/requests/requests.component';
import { UsageComponent } from './user/usage/usage.component';
import { DossierComponent } from './pages/dossier/dossier/dossier.component';
import { DepositsComponent } from './user/deposits/deposits.component';
import { BilanciComponent } from './pages/vv/bilanci/bilanci.component';
import { GeoCatastoComponent } from './pages/vv/geocatasto/geocatasto.component';
import { RicercheComponent } from './pages/vv/ricerche/ricerche.component';
import { ReoperaPlusComponent } from './pages/reopera-plus/reopera-plus.component';
import { UserIsactiveGuard } from './user/user.isactive.guard';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { WorkareaListComponent } from './user/workarea-list/workarea-list.component';
import { DynamicWorkareaComponent } from './shared/components/workarea-v2/workarea.component';
import { MappaComponent } from './pages/vv/geocatasto/mappa/mappa.component';

export const routes: Routes = [{
  path: '',
  component: HomeComponent,
  children: [
    {
      path: '',
      pathMatch: 'prefix',
      redirectTo: 'user',
    },
    {
      path: 'user',
      canActivate: [AuthGuard],
      data: { expectedRole: ROLE.USER, name: 'Home' },
      children: [{
        path: '',
        canActivate: [UserIsactiveGuard],
        component: WorkareaListComponent,
      }, {
        path: 'work-in-progress',
        component: WorkInProgressComponent
      }, {
        path: 'assistenza',
        component: HelpdeskComponent
      }, {
        path: 'balance',
        component: BalanceComponent
      }, {
        path: 'deposits',
        component: DepositsComponent
      }, {
        path: 'usage',
        component: UsageComponent
      }, {
        path: 'requests',
        component: RequestsComponent
      },
      {
        canActivate: [UserIsactiveGuard],
        path: 'service/:id',
        component: RequestformComponent
      },
      {
        path: 'messages',
        component: MessagesComponent
      },
      { path: 'pending', component: ForbiddenComponent },
      {
        path: 'atti-immobiliari-pregiudizievoli',
        component: PregiudizievoliComponent
      },
      {
        path: 'atti-immobiliari-pregiudizievoli/:uuid',
        component: ListaPregiudizievoliComponent
      },
      {
        path: 'catasto/consultazione',
        component: ConsultazioneCatastoComponent
      },
      {
        path: 'catasto/monitoraggio',
        component: MonitoraggioReoperaComponent
      },
      {
        path: 'catasto/mappe',
        component: GeoCatastoComponent
      },
      {
        path: "conservatoria",
        component: ConservatoriaComponent
      },
      {
        path: 'conservatoria/:uuid',
        component: ListaFormalitaComponent
      },
      {
        path: 'ricerche',
        component: RicercheComponent
      },
      {
        path: 'bilanci',
        component: BilanciComponent
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        data: {
          name: "Archivio documenti",
          icon: 'fa-file-lines',
        }
      },
      {
        canActivate: [UserIsactiveGuard],
        path: 'wa/secure-suite',
        component: SecuresuiteComponent
      },
      {
        path: 'wa/:workarea_slug',
        canActivate: [UserIsactiveGuard],
        children: [{
          path: "",
          component: DynamicWorkareaComponent
        },
        {
          path: ':item_slug',
          children: [{
            path: '',
            component: DynamicWorkareaComponent
          }, {
            path: ':id',
            component: RequestformComponent
          }
          ]
        }]
      }]
    },
  ]
},
{ path: '**',  redirectTo: 'user' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
