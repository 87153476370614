<div class="w-full h-full px-6 py-6 text-black dark:text-white grid md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2  gap-4"
    [ngClass]="[(columns==4) ? 'xl:grid-cols-3 2xl:grid-cols-4' : (filteredItems.length == 3 || columns == 3) ? 'xl:grid-cols-3 2xl:grid-cols-3': (filteredItems.length == 2 || columns == 2)? '2xl:grid-cols-2 xl:grid-cols-2': 'xl:grid-cols-3 2xl:grid-cols-4']">
    <ng-container *ngFor="let item of filteredItems">
        <a [routerLink]="preventRoute ? null : item.path" (click)="preventRoute && onServiceSelected.emit(item)"
            [attr.data-cy]="item.path"
            *ngIf="!item.data?.['layout'] || item.data?.['layout']=='base'|| item.data?.['layout']=='vertical'"
            class="group flex flex-col  hover:drop-shadow-xl hover:scale-105 hover:bg-opacity-100 px-5 pt-5 pb-4 w-full duration-500 delay-300 transition-all"
            [ngClass]="[
            item.data?.['noCard'] ? '' : 'min-h-[240px] h-full  bg-white dark:bg-black border relative group hover:shadow-lg',
            item.data?.['highlightBox'] ? item.data?.['highlightContainerClass'] || 'border-orange-500 shadow-orange-500 rounded-3xl': ' border-secondary  shadow-secondary  rounded-lg']">


            <div *ngIf="!item.data?.['noCard']" class="flex space-x-2 mb-1">
                <p class="w-full h-20 text-3xl font-bold"
                    [ngClass]="[item.data?.['layout']=='vertical'?'text-center': '']">
                    {{item.data?.['name']}}
                </p>
            </div>

            <div class="justify-between" [ngClass]="[ item.data?.['layout']=='base'? 'flex h-full': '']">
                <p *ngIf="!item.data?.['noCard']" class=" mt-2"
                    [ngClass]="[item.data?.['layout']=='vertical'? 'text-center': '']">
                    {{item.data?.['intro']}}
                </p>


                <div class="flex-grow flex justify-center text-primary group-hover:text-secondary transition-colors duration-1000 delay-300 "
                    *ngIf="item.data?.['imgUrl']; else icon" [ngClass]="[item.data?.['noCard'] ? 'w-full h-full':'']">
                    <img *ngIf="!item.data?.['noCard']" class=" w-32 h-32 object-contain" [src]="item.data?.['imgUrl']"
                        style="max-width: auto;" />
                    <div *ngIf="item.data?.['noCard']" [style]="'background-image:url('+item.data?.['imgUrl']+');'"
                        class="w-full h-full bg-contain bg-no-repeat bg-center">
                    </div>
                </div>
                <ng-template #icon>
                    <div class="flex-grow flex duration-1000 delay-100 transition-all" *ngIf="item.data?.['icon']"
                        [ngClass]="[
                item.data?.['layout']=='base'?'justify-end': 'justify-center',
                item.data?.['highlightBox']? item.data?.['highlightIconClass'] ||'text-orange-400 group-hover:text-orange-600':'text-secondary group-hover:text-primary ' 
                ]">
                        <i class="w-32 h-32 fa-solid mr-1.5" [ngClass]="[item.data?.['icon'] || '']"></i>
                    </div>
                </ng-template>


            </div>

            <!-- <div *ngIf="item.data?.['noCard']" class="flex-grow flex justify-between">
                <p class="justify-center text-2xl flex flex-grow">
                    {{item.data?.['name']}}
                </p>
            </div> -->
        </a>
        <ng-container *ngIf="item.data?.['layout']=='service'">
            <app-service-card *ngIf="item.data?.['serviceSlug'] else fakeService" [slug]="item.data?.['serviceSlug']"
                [forceLink]="item.path || item.data?.['serviceSlug'] " [buttonText]="item.data?.['buttonText']"
                [serviceIcon]="item.data?.['icon']" [serviceImg]="item.data?.['img']" [preventRoute]="preventRoute"
                (onServiceSelected)="onServiceSelected.emit($event)">
            </app-service-card>
            <ng-template #fakeService>
                <div
                    class="w-full text-black dark:text-white flex flex-col min-h-[240px] rounded-lg 
                pt-6 h-full bg-white dark:bg-black border-primary dark:border-secondary border group transition-all hover:shadow-lg">
                    <div class="px-5 flex-grow flex flex-col">
                        <div class="flex items-start justify-between mb-1">
                            <h2 class="text-2xl font-bold flex flex-shrink">
                                <span>{{item.data?.['name']}}</span>
                            </h2>
                            <div *ngIf="!!item.data?.['price']"
                                class="flex flex-grow l-1 justify-center items-center px-2 py-1 text-xs rounded-full text-yellow-600  bg-yellow-200 ">
                                <svg class="h-5 w-5 text-yellow-600" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>a&nbsp;pagamento
                            </div>
                        </div>
                        <div class="flex-grow">
                            <div class="pb-2 my-2 h-[4.5rem]">
                                <p class="overflow-hidden max-h-[4.5rem] max-w-full">
                                    {{item.data?.['intro']}}
                                </p>


                            </div>
                            <!-- <button *ngIf="item.data?.['intro']" class="text-primary">Leggi
                                tutto</button> -->
                        </div>

                    </div>

                    <div
                        class="text-sm w-full font-semibold flex justify-end gap-2 text-white bg-primary py-3 px-5 rounded-b-lg  border-t border-gray-200 transition-colors">
                        <a [routerLink]="item.path" data-cy="service-link"
                            class="px-2 py-1 border-white hover:text-primary hover:bg-white  border rounded transition-colors">
                            ENTRA
                        </a>
                    </div>


                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</div>