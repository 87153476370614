import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseCardComponent } from './components/base-card/base-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PushNotificationsService } from './pushnotification.service';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { GlobalActionpopupComponent } from './components/actionpopup/actionpopup.component';
import { ActionpopupComponent } from './components/actionpopup-reusable/actionpopup.component';
import { UploadComponent } from './components/upload/upload.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { DndUploadDirective } from './dnd-upload.directive';
import { WorkareaComponent } from './components/workarea/workarea.component';
import { MacroserviceComponent } from './components/macroservice/macroservice.component';
import { ServiceCardComponent } from './components/service.card/service.card.component';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from './components/loading/loading.component';
import { NavigazioneFormComponent } from './components/navigazione-form/navigazione-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigazioneListviewComponent } from './components/navigazione-listview/navigazione-listview.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NavigazioneContainerComponent } from './components/navigazione-container/navigazione-container.component';
import { NavigazioneCardComponent } from './components/navigazione-card/navigazione-card.component';
import { SubmissionConfirmComponent } from './components/submission-confirm/submission-confirm.component';
import { NavigazioneResultsComponent } from './components/navigazione-results/navigazione-results.component';
import { SelectComponent } from './components/select/select.component';
import { DateSelectorComponent } from './components/dateselector/dateselector.component';
import { InputComponent } from './components/input/input.component';
import { WorkInProgressComponent } from './components/work-in-progress/work-in-progress.component';
import { ServiceSelectionPopupComponent } from './components/service-selection-popup/service-selection-popup.component';
import { LightComponent } from './components/light/light.component';
import { AdminFeatureComponent } from './components/admin-feature/admin-feature.component';
import {DynamicWorkareaComponent} from './components/workarea-v2/workarea.component'
import { RequestformComponent } from '../pages/vv/components/requestform/requestform.component';
import { ReportPreviewComponent } from '../pages/vv/components/report-preview/report-preview.component';


@NgModule({
  declarations: [
    BaseCardComponent,
    AutocompleteComponent,
    GlobalActionpopupComponent,
    ActionpopupComponent,
    UploadComponent,
    UploadProgressComponent,
    DndUploadDirective,
    WorkareaComponent,
    DynamicWorkareaComponent,
    MacroserviceComponent,
    ServiceCardComponent,
    LoadingComponent,
    SubmissionConfirmComponent,
    NavigazioneFormComponent,
    NavigazioneListviewComponent,
    NavigazioneContainerComponent,
    NavigazioneCardComponent,
    NavigazioneResultsComponent,
    SelectComponent,
    DateSelectorComponent,
    InputComponent,
    WorkInProgressComponent,
    ServiceSelectionPopupComponent,
    LightComponent,
    AdminFeatureComponent,
    RequestformComponent,
    ReportPreviewComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AutocompleteLibModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AutocompleteLibModule,
    NgxPaginationModule,
  ],
  exports: [
    BaseCardComponent,
    AutocompleteComponent,
    ActionpopupComponent,
    GlobalActionpopupComponent,
    UploadComponent,
    WorkareaComponent,
    DynamicWorkareaComponent,
    MacroserviceComponent,
    ServiceCardComponent,
    LoadingComponent,
    NavigazioneContainerComponent,
    NavigazioneResultsComponent,
    NavigazioneCardComponent,
    NavigazioneListviewComponent,
    SubmissionConfirmComponent,
    NavigazioneFormComponent,
    SelectComponent,
    DateSelectorComponent,
    InputComponent,
    WorkInProgressComponent,
    ServiceSelectionPopupComponent,
    AdminFeatureComponent,
    RequestformComponent,
    ReportPreviewComponent
  ],
  providers: [PushNotificationsService]
})
export class SharedModule {

}
